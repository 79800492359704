import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSnip from 'vue-snip'

import firebase from 'firebase'
import { auth } from './firebase'
import router from './router'
import store from './store'
import App from './App.vue'

// import VueTour from 'vue-tour'
// require('vue-tour/dist/vue-tour.css')
 
// Vue.use(VueTour)

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import './registerServiceWorker'

// Firebase Configurations
export const db = firebase.firestore()

let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }
})

// Vue.use(VueFirebase, {firebase: firebase, config: FBCONFIG});
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Vue-snip (i.e. trim to n lines)
Vue.use(VueSnip)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// new Vue({
//   router,
//   store,
//   render: h => h(App),
// }).$mount('#app')
