import { db } from '@/main'
import { format } from 'date-fns'

export default {
  namespaced: true,

  state: {
    // channel: [],  // channel is the selected tasks (or empty task) for responses
    updateList: false, // test only
    listUpdated: false, // test only
    responses: [],
    response: {},
  },

  getters: {
    fetchResponsesByParentId: (state) => (id) => {
      return state.responses.filter(response => response.zParentId === id)
    },

    fetchResponsesById: (state) => (id) => {
      return state.responses.filter(response => response.id === id)
    },
  },

  actions: {
    setUpdateList({ commit }, payload) {
        commit('SET_UPDATELIST', payload)
    },
    setlistUpdated({ commit }, payload) {
        commit('SET_LISTUPDATED', payload)
    },
    async getResponses({ commit }, payload) {
        // commit('SET_LOADINGSTATUS', 'loading')
        let snapshot = await db.collection("responses")
          .where('zCusId', '==', payload)
          .get();
        let responses = [];
        snapshot.forEach(doc => {
            let appData = doc.data()
            appData.id = doc.id
            responses.push(appData)
        })
        commit('SET_RESPONSES', responses)
        // commit('SET_LOADINGSTATUS', 'notLoading')
        // console.log('responses loaded')
    },
    async getDeltaResponses({ commit, rootState }, payload) {
        // commit('SET_LOADINGSTATUS', 'loading')
        let snapshot = await db.collection("responses")
          .where('zCusId', '==', payload)
          .where('createdAt', '>=', rootState.authen.loginTime + '\uf8ff')
          .limit(50)
          .get();
        let deltaResponses = [];
        snapshot.forEach(doc => {
            let appData = doc.data()
            appData.id = doc.id
            deltaResponses.push(appData)
        })
        commit('ADD_DELTARESPONSES', deltaResponses)
        // commit('SET_LOADINGSTATUS', 'notLoading')
        // console.log('deltaResponses loaded')
    },
    async addResponse({ commit, dispatch }, payload) {
        await db
          .collection('responses')
          .add({
              emoji: payload.emoji,
              userId: payload.userId,
              createdAt: payload.createdAt,
              zParentId: payload.zParentId,
              zParentType: payload.zParentType,
              zCusId: payload.zCusId
          })
          .then(function (docRef) {
              payload.id = docRef.id
          })
        commit('SET_RESPONSE', payload)
        commit('ADD_TORESPONSES', payload)
              
        payload.instr = 'Add'
        dispatch('packageHistoryItem', payload)
    },
    // No Update in Response
    // async updateResponse({ commit }, payload) {
    //     await db
    //         .collection('responses')
    //         .doc(payload.id)
    //         .update({
    //             emoji: payload.emoji,
    //             userId: payload.userId,
    //             createdAt: payload.createdAt,
    //             zParentId: payload.zParentId,
    //             zParentType: payload.zParentType,
    //             zCusId: payload.zCusId
    //         })
    //     console.log('-------------' +payload.emoji.emoji)
    //     commit('SET_RESPONSE', payload)
    // },
    async deleteResponse({ state, dispatch }, payload) {
      // delete history item first
      payload.instr = 'Delete'
      dispatch('packageHistoryItem', payload)

      let payloadIndex = -1
      await db
        .collection('responses')
        .doc(payload.id)
        .delete()
        .then(() => {
              payloadIndex = state.responses.indexOf(payload)
              if (payloadIndex > -1) {
                state.responses.splice( payloadIndex, 1 )
              }
          })
          .catch((error) => {
              console.error("Error removing response: ", error);
          })
    },
    async packageHistoryItem({ dispatch, rootState }, payload) {
  
      // 1. prepare historyItem fields (other than 'action')
      let historyItem = {}
      let fuAction = ''

      switch ( payload.emoji ){
        case 'ThumbsUpIcon':
          historyItem.module = 'Like'
          break
        case 'HeartIcon':
          historyItem.module = 'Heart'
          break
        case 'SmileIcon':
          historyItem.module = 'Happy'
          break
        case 'FrownIcon':
          historyItem.module = 'Sad'
          // break
        // Skip handling Reply (i.e. in 'Add')
        // case 'CornerDownLeftIcon':
        //   historyItem.module = 'Comment'
        //   break
      }
      
      historyItem.moduleId = payload.id
      historyItem.moduleParentId = payload.zParentId
      historyItem.moduleParentType = 'Comment'
  
      historyItem.at = format(new Date(
          payload.createdAt
        ), "yyyy-MM-dd HH:mm")
      historyItem.by = payload.userId
      historyItem.usersNotified = payload.usersNotified
      historyItem.zCusId = payload.zCusId
  
      // 2. prepare 'action' field acc. to payload.instr': 
      //  'Add' / 'Update' / 'Remove' history record        (task / comment / doc / note / blog )
      //  'Like' / 'Heart' / 'Happy' / 'Sad' history record (comment)
      // * only task and comment in notifications           (i.e. 'at' and 'by' fields)
      
      switch ( payload.instr ) {
        case 'Add':
          historyItem.action = 'Add'
          dispatch('history/addHistoryItem', historyItem, { root: true })
          break
        case 'Update':
          historyItem.action = 'Update'
          fuAction = 'Update'
          break
        case 'Remove':
          historyItem.action = 'Remove'
          fuAction = 'Remove'
          break
        case 'Delete':
          historyItem.action = 'Delete'
          fuAction = 'Delete'
      }

      // Proceed if 'Update' or 'Remove' or 'Delete'
      if ( fuAction !== '' ) {
        // Dig out the exiting historyItem from store, grab the id before being updated
        let mappedHistItem = rootState.history.historyItems.find(hItem => hItem.moduleId===payload.id)
        if ( mappedHistItem !== undefined ) {
          historyItem.id = mappedHistItem.id
          if ( fuAction == 'Remove' ) {
            historyItem.at = ''
            historyItem.usersNotified = []
          }
          if ( fuAction=='Update' || fuAction=='Remove' ) {
            dispatch('history/updateHistoryItem', historyItem, { root: true })
          }
          if ( fuAction == 'Delete' ) {
            dispatch('history/deleteHistoryItem', historyItem, { root: true })
          }
        }
      }
  
      // remove 'instr' field from payload
      delete payload.instr
      delete payload.usersNotified
      fuAction = ''
    },
    setResponses({ commit }, payload) {
        commit('SET_RESPONSES', payload)
    },
    clearResponses({ commit }, payload) {
        commit('CLEAR_RESPONSES', payload)
    },
    setResponse({ commit }, payload) {
        commit('SET_RESPONSE', payload)
    },
  },

  mutations: {
    SET_UPDATELIST(state, updateList) {
      state.updateList = updateList
    },
    SET_LISTUPDATED(state, listUpdated) {
      state.listUpdated = listUpdated
    },
    // SET_CHANNEL(state, channel) {
    //     state.channel = channel
    // },
    SET_RESPONSE(state, payload) {
      state.response = payload
      // 2. Update 'response' into the existing "responses" array
      state.responses.forEach(eachResponse => {
        if (eachResponse.id === payload.id) {
          // eachResponse.commentId = payload.commentId,
          eachResponse.emoji = payload.emoji,
          eachResponse.userId = payload.userId,
          eachResponse.createdAt = payload.createdAt,
          eachResponse.zParentId = payload.zParentId,
          eachResponse.zParentType = payload.zParentType,
          eachResponse.zCusId = payload.zCusId
        }
      })
      // state.response.update
    },
    SET_RESPONSES(state, payload) {
      state.responses = payload
    },
    CLEAR_RESPONSES(state) {
      state.response = {}
      state.responses = []
    },
    ADD_TORESPONSES(state, payload) {
      let isResponseFound = false
      state.responses.forEach(response => {
        if (response.id === payload.id) {
          isResponseFound = true
        }
      })
      if (!isResponseFound) {
        state.responses.push(payload)
      }
    },
    ADD_DELTARESPONSES(state, payload) {
      let isFound = false
      payload.forEach(deltaResponse => {
        state.responses.forEach(response => {
          if (response.id === deltaResponse.id) {
            isFound = true
          }
        })
        if (!isFound) {
          state.responses.push(deltaResponse)
        } else {
          isFound = false
        }
      })
      // state.responses.update
    },
  },

}
