// eslint-disable-next-line import/no-cycle
import { db } from '@/main'
export default {
  namespaced: true,

  state: {
    products: [],
  },

  actions: {
    // 'productss' is goalpen products
    // 'products' is stripe products (in stripe.js)
    async getProducts({ commit }) {
      let snapshot = await db.collection('productss')
      .get()
      let aproducts = [];
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        aproducts.push(appData)
      })
      commit('SET_PRODUCTS', aproducts)
    },
  },

  getters: {},

  mutations: {
    SET_PRODUCTS(state, payload) {
      state.products = payload
    },
  },

}
