import { db } from '@/main'
import { format } from 'date-fns'
import arrObjOperation from '@/mixins/arrObjOperation'

export default {
  namespaced: true,

  mixins: [ arrObjOperation ],

  state: {
    // channel: [],  // channel is the selected tasks (or empty task) for notes
    notes: [],
    note: {},
    noteView: 'list',
    addNoteTag: false,
    noteTags: [],
    parentTaskId: '',
  },

  getters: {
    // fetchNotesByParentId: (state) => (id) => {
    //     return state.notes.filter(note => note.zParentId === id)
    // },

    // fetchNotesById: (state) => (id) => {
    //     return state.notes.filter(note => note.id === id)
    // },
  },

  actions: {
    // 1st JOB - UI - put it into a separate component
    // 2nd JOB - Data Extraction of PLUS Plan:
    // - Any plan - try to avoid omittence - # becomes inaccurate
    // - NON PLUS - (a) My Private + (b)Public
    // - PLUS - (c) My roles + My Private + PUblic
    // 3 x actions at start + concat + sort; b & c delta read at random time
    // i.e. 5 actions: Note / Blog / Doc / Task / Channel (each different!)
    async getNotes({ commit, rootState, dispatch }, payload) {
      let snapshot = []
      switch ( rootState.site.loadingDataStage ) {
        case 'mine':
          // console.log('Loading my notes')
          snapshot = await db.collection('notes')
            .where('zCusId', '==', payload.zCusId)
            .where('zAccess', 'array-contains', rootState.authen.privateId)
            .where('owner', '==', payload.id)
            .limit(500)
            .get()
          break
        case 'public':
          // console.log('Loading public notes')
          snapshot = await db.collection('notes')
            .where('zCusId', '==', payload.zCusId)
            .where('zAccess', 'array-contains', rootState.authen.publicId)
            .limit(500)
            .get()
          break
        case 'roles':
          // console.log('Loading roles notes')
          // Remove 'Public' in roles
          let roles = payload.roles
          let publicIndex = -1
          publicIndex = roles.indexOf(rootState.authen.publicId)
          if (publicIndex > -1) {
            roles.splice( publicIndex, 1 )
          }
          snapshot = await db.collection('notes')
            .where('zCusId', '==', payload.zCusId)
            .where('zAccess', 'array-contains-any', roles)
            .limit(500)
            .get()
      }
        let notes = [];
        snapshot.forEach(doc => {
            let appData = doc.data()
            appData.id = doc.id
            notes.push(appData)
        })
        commit('SET_NOTES', notes)
        dispatch('orderTagsFromNotes')
        // dispatch('orderTagsFromNotes', notes)
    },
    async getMoreNotes({ commit, dispatch, rootState }, payload) {
      let snapshot = []
      switch ( rootState.site.loadingDataStage) {
        case 'mine':
          // console.log('Loading my other notes')
          snapshot = await db.collection('notes')
            .where('zCusId', '==', payload.zCusId)
            .where('zAccess', 'array-contains', rootState.authen.privateId)
            .where('owner', '==', payload.id)
            .limit(500)
            .get();
          break
        case 'public':
          // console.log('Loading public other notes')
          snapshot = await db.collection('notes')
            .where('zCusId', '==', payload.zCusId)
            .where('zAccess', 'array-contains', rootState.authen.publicId)
            .limit(500)
            .get()
          break
        case 'roles':
          // console.log('Loading roles other notes')
          // Remove 'Public' in roles
          let roles = payload.roles
          let publicIndex = -1
          publicIndex = roles.indexOf(rootState.authen.publicId)
          if (publicIndex > -1) {
            roles.splice( publicIndex, 1 )
          }
          snapshot = await db.collection('notes')
            .where('zCusId', '==', payload.zCusId)
            .where('zAccess', 'array-contains-any', roles)
            .limit(500)
            .get()
      }
      let notes = [];
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        notes.push(appData)
      })
      commit('SET_NOTES', notes)
      dispatch('orderTagsFromNotes')
      // dispatch('orderTagsFromNotes', notes)
    },
    async getDeltaNotes({ commit, rootState }, payload) {
      let snapshot = []
      switch ( rootState.site.loadingDataStage ) {
        case 'mine_delta':
          snapshot = await db.collection('notes')
            .where('zCusId', '==', payload.zCusId)
            .where('zAccess', 'array-contains', rootState.authen.privateId)
            .where('owner', '==', payload.id)
            .where('updatedAt', '>=', rootState.authen.loginTime + '\uf8ff')
            .limit(50)
            .get()
          break
        case 'public_delta':
          snapshot = await db.collection('notes')
            .where('zCusId', '==', payload.zCusId)
            .where('zAccess', 'array-contains', rootState.authen.publicId)
            .where('updatedAt', '>=', rootState.authen.loginTime + '\uf8ff')
            .limit(50)
            .get()
          break
        case 'roles_delta':
          // Remove 'Public' in roles
          let roles = payload.roles
          let publicIndex = -1
          publicIndex = roles.indexOf(rootState.authen.publicId)
          if (publicIndex > -1) {
            roles.splice( publicIndex, 1 )
          }
          snapshot = await db.collection('notes')
            .where('zCusId', '==', payload.zCusId)
            .where('zAccess', 'array-contains-any', roles)
            .where('updatedAt', '>=', rootState.authen.loginTime + '\uf8ff')
            .limit(50)
            .get()
      }
      let deltaNotes = [];
      snapshot.forEach(doc => {
          let appData = doc.data()
          appData.id = doc.id
          deltaNotes.push(appData)
      })
      commit('ADD_DELTANOTES', deltaNotes)

      deltaNotes.forEach(deltaNote => {
        commit('ADD_NOTETAGS', deltaNote.tags)
      })
  },
    // async getNotes({ commit, dispatch }, payload) {
    //     // commit('SET_LOADINGSTATUS', 'loading')
    //     let snapshot = await db.collection("notes")
    //       .where('zCusId', '==', payload.zCusId)
    //       .where('zAccess', 'array-contains', payload.roles[0])
    //       .get();
    //     let notes = [];
    //     snapshot.forEach(doc => {
    //         let appData = doc.data()
    //         appData.id = doc.id
    //         notes.push(appData)
    //     })
    //     // Omit other private notes
    //     // notes = notes.filter(n => 
    //     //   !n.private || (n.private && n.owner===payload.id)
    //     // )
    //     commit('SET_NOTES', notes)
    //     dispatch('orderTagsFromNotes', notes)
    //     // commit('SET_LOADINGSTATUS', 'notLoading')
    // },
    // async getDeltaNotes({ commit, rootState }, payload) {
    //     let snapshot = await db.collection('notes')
    //       .where('zCusId', '==', payload.zCusId)
    //       .where('updatedAt', '>=', rootState.authen.loginTime + '\uf8ff')
    //       .limit(50)
    //       .get();
    //     let deltaNotes = [];
    //     snapshot.forEach(doc => {
    //         let appData = doc.data()
    //         appData.id = doc.id
    //         deltaNotes.push(appData)
    //     })
    //     // Omit other private deltaNotes
    //     deltaNotes = deltaNotes.filter(n => 
    //       !n.private || (n.private && n.owner===payload.id)
    //     )
    //     commit('ADD_DELTANOTES', deltaNotes)

    //     deltaNotes.forEach(deltaNote => {
    //       commit('ADD_NOTETAGS', deltaNote.tags)
    //     })
    // },
    async addNote({ commit, dispatch }, payload) {
      await db
        .collection('notes')
        .add({
            noteTitle: payload.noteTitle,
            content: payload.content,
            owner: payload.owner,
            // private: payload.private,
            checked: payload.checked,
            color: payload.color,
            pin: payload.pin,
            tags: payload.tags,
            zCusId: payload.zCusId,
            zParentId: payload.zParentId,
            zParentType: payload.zParentType, //'task' or 'goal'
            zAccess: payload.zAccess,
            createdAt: payload.createdAt,
            updatedAt: payload.updatedAt
        })
        .then(function (docRef) {
            payload.id = docRef.id
        })
      commit('SET_NOTE', payload)
      commit('ADD_TONOTES', payload)
      commit('ADD_NOTETAGS', payload.tags)
  
      payload.instr = 'Add'
      dispatch('packageHistoryItem', payload)
    },
    async updateNote({ commit, dispatch, state }, payload) {
      await db
          .collection('notes')
          .doc(payload.id)
          .update({
            noteTitle: payload.noteTitle,
            content: payload.content,
            owner: payload.owner,
            // private: payload.private,
            checked: payload.checked,
            color: payload.color,
            pin: payload.pin,
            tags: payload.tags,
            zCusId: payload.zCusId,
            zParentId: payload.zParentId,
            zParentType: payload.zParentType, //'task' or 'goal'
            zAccess: payload.zAccess,
            createdAt: payload.createdAt,
            updatedAt: payload.updatedAt
          })
      commit('SET_NOTE', payload)
      dispatch('orderTagsFromNotes')
      // dispatch('orderTagsFromNotes', state.notes)
        
      payload.instr = 'Update'
      dispatch('packageHistoryItem', payload)
    },
    async deleteNote({ state, dispatch }, payload) {
      // delete history item first
      payload.instr = 'Delete'
      dispatch('packageHistoryItem', payload)
      
      let payloadIndex = -1
      await db.collection("notes").doc(payload.id).delete().then(() => {
          payloadIndex = state.notes.indexOf(payload)
          if (payloadIndex > -1) {
            state.notes.splice( payloadIndex, 1 )
          }
      }).catch((error) => {
          console.error("Error removing note: ", error);
      });

      dispatch('orderTagsFromNotes')
      // dispatch('orderTagsFromNotes', state.notes)
    },
    orderTagsFromNotes({ commit, state }) {
      let noteTagsArray = []
      // let noteTagsArray = state.noteTags
      let uniqueArray = []
  
      // 1. Concat tags from all notes.tags into noteTagsArray
      state.notes
        .forEach(note => noteTagsArray = noteTagsArray.concat(note.tags))
  
      // 2. Dudup noteTagsArray
      noteTagsArray.forEach(tag => {
        if ( uniqueArray.indexOf(tag)==-1 && tag.trim()!=='' ) {
          uniqueArray.push(tag)
        }
      })
  
      commit('SET_NOTETAGS', uniqueArray)
    },
    async packageHistoryItem({ dispatch, rootState }, payload) {
  
      // 1. prepare historyItem fields (other than 'action')
      let historyItem = {}
      let fuAction = ''
      
      historyItem.module = 'Note'
      historyItem.moduleId = payload.id
      historyItem.moduleParentId = payload.zParentId
      historyItem.moduleParentType = payload.zParentType
      historyItem.at = format(new Date(payload.updatedAt), "yyyy-MM-dd HH:mm")
      historyItem.by = payload.owner
      historyItem.usersNotified = []
      historyItem.zCusId = payload.zCusId
  
      // 2. prepare 'action' field acc. to payload.instr': 
      //  'Add' / 'Update' / 'Remove' history record        (task / comment / doc / note / blog )
      //  'Like' / 'Heart' / 'Happy' / 'Sad' history record (comment)
      // * only task and comment in notifications           (i.e. 'at' and 'by' fields)
      
      switch ( payload.instr ) {
        case 'Add':
          historyItem.action = 'Add'
          dispatch('history/addHistoryItem', historyItem, { root: true })
          break
        case 'Update':
          historyItem.action = 'Update'
          fuAction = 'Update'
          break
        case 'Delete':
          historyItem.action = 'Delete'
          fuAction = 'Delete'
      }
  
      // Proceed if 'Update' or 'Remove' or 'Delete'
      if ( fuAction !== '' ) {
        // Dig out the exiting historyItem from store, grab the id before being updated
        let mappedHistItem = rootState.history.historyItems.find(hItem => hItem.moduleId===payload.id)
        if ( mappedHistItem !== undefined ) {
          historyItem.id = mappedHistItem.id
          if ( fuAction == 'Update' ) {
            dispatch('history/updateHistoryItem', historyItem, { root: true })
          }
          if ( fuAction == 'Delete' ) {
            dispatch('history/deleteHistoryItem', historyItem, { root: true })
          }
        }
      }
  
      // remove 'instr' field from payload
      delete payload.instr
      delete payload.usersNotified
      fuAction = ''
    },
    // async deleteNote({ commit }, payload) {
    //     await db
    //         .collection('notes')
    //         .doc(payload)
    //         .delete();
    //     // alert('The note item has been deleted.');
    //     // commit('SET_NOTE', null)
    //     commit('REMOVE_NOTES', payload)
    // },
    setNote({ commit }, payload) {
        commit('SET_NOTE', payload)
    },
    setAddNoteTag({ commit }, payload) {
      commit('SET_ADDNOTETAG', payload)
    },
    setNoteView({ commit }, payload) {
      commit('SET_NOTEVIEW', payload)
    },
    setParentTaskId({ commit }, payload) {
      commit('SET_PARENTTASKID', payload)
    },
    // only be called during logout
    clearNote({ commit }) {
      commit('CLEAR_NOTE')
    },
  },

  mutations: {
    // SET_UPDATELIST(state, updateList) {
    //     state.updateList = updateList
    // },
    // SET_LISTUPDATED(state, listUpdated) {
    //     state.listUpdated = listUpdated
    // },
    SET_NOTE(state, payload) {
      state.note = payload
      // 2. Update 'note' into the existing "notes" array
      state.notes.forEach(eachNote => {
        if (eachNote.id === payload.id) {
          eachNote.noteTitle = payload.noteTitle,
          eachNote.content = payload.content,
          eachNote.owner = payload.owner,
          // eachNote.private = payload.private,
          eachNote.checked = payload.checked,
          eachNote.color = payload.color,
          eachNote.pin = payload.pin,
          eachNote.tags = payload.tags,
          eachNote.zCusId = payload.zCusId,
          eachNote.zParentId = payload.zParentId,
          eachNote.zParentType = payload.zParentType,
          eachNote.zAccess = payload.zAccess,
          eachNote.createdAt = payload.createdAt,
          eachNote.updatedAt = payload.updatedAt
        }
      })
      // state.notes.update
    },
    SET_NOTES(state, payload) {
      if ( !state.notes.length ) {
        state.notes = payload
      } else {
        state.notes = arrObjOperation.methods.dedupArr(state.notes, payload)
      }
    },
    SET_PARENTTASKID(state, payload) {
      state.parentTaskId = payload
    },
    ADD_TONOTES(state, payload) {
      let isNoteFound = false
      state.notes.forEach(note => {
        if (note.id === payload.id) {
          isNoteFound = true
        }
      })
      if (!isNoteFound) {
        state.notes.push(payload)
      }
    },
    ADD_DELTANOTES(state, payload) {
      let isFound = false
      payload.forEach(deltaNote => {
        state.notes.forEach(note => {
          if (note.id === deltaNote.id) {
            isFound = true
          }
        })
        if (!isFound) {
          state.notes.push(deltaNote)
        } else {
          // Still have to update the note!
          let updatedNoteIndex = state.notes.findIndex(note => note.id == deltaNote.id)
          state.notes[updatedNoteIndex] = deltaNote
          state.notes.update
          isFound = false
        }
      })
      // state.notes.update
    },
    REMOVE_NOTES(state, payload) {
        state.notes.pop(payload)
        state.notes.update
        // state.notes = state.notes.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
    },
    SET_ADDNOTETAG(state, payload) {
      state.addNoteTag = payload
    },
    SET_NOTEVIEW(state, payload) {
      state.noteView = payload
    },
    SET_NOTETAGS(state, payload) {
      state.noteTags = payload
      // if ( state.noteTags != [] ) {
      //   console.log('-- noteTags NOT EMPTY')
      //   payload.forEach(newTag => {
      //     let newTagIndex = state.noteTags.indexOf(newTag)
      //     if ( newTagIndex==-1 && newTag.trim()!=='' ) {
      //       state.noteTags.push(newTag)
      //     }
      //   })
      // } else {
      //   console.log('-- noteTags EMPTY')
      //   state.noteTags = payload
      // }
    },
    ADD_NOTETAGS(state, payload) {
      payload.forEach(newTag => {
        let newTagIndex = state.noteTags.indexOf(newTag)
        if ( newTagIndex==-1 && newTag.trim()!=='' ) {
          state.noteTags.push(newTag)
        }
      })
      // state.noteTags.push( 
      //   noteTags.find(x => !state.noteTags.includes(x)) 
      // )
    },    
    CLEAR_NOTE(state) {
      state.notes = []
      state.note = {}
      state.noteView = ''
      state.noteTags = []
    },
  },
}
