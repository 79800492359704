// eslint-disable-next-line import/no-cycle
import { db } from '@/main'
// import { doc, getDoc } from "firebase/firestore"

const state = () => ({
  channels: [],
  channel: {},
  isChannelDeleted: false,  // flag channel deleted and show 'Start Conversation'
  selectedChannelIdRParam: '', // Router parameter stored value
  routeFromRParam: '',
})

const getters = {
};

const actions = {
  async getChannels({ commit }, payload) {
    let snapshot = await db.collection('channels')
      .where('zCusId', '==', payload)
      .limit(50)
      .get();
    let channels = [];
    snapshot.forEach(doc => {
      let appData = doc.data()
      appData.id = doc.id
      channels.push(appData)
    })
    commit('SET_CHANNELS', channels)
  },
  async getDeltaChannels({ commit, rootState }, payload) {
    let snapshot = await db.collection('channels')
      .where('zCusId', '==', payload)
      .where('updatedAt', '>=', rootState.authen.loginTime + '\uf8ff')
      .limit(50)
      .get();
    let deltaChannels = [];
    snapshot.forEach(doc => {
      let appData = doc.data()
      appData.id = doc.id
      deltaChannels.push(appData)
    })
    commit('ADD_DELTACHANNELS', deltaChannels)
  },
  async addChannel({ commit }, payload) {
    // console.log('addChannel run: ' + payload.id)
    await db
        .collection('channels')
        .add({
          channelName: payload.channelName,
          assignee: payload.assignee,
          owner: payload.owner,
          deleted: payload.deleted,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          zCusId: payload.zCusId,
          // zParentTask: payload.zParentTask,
          zParentId: payload.zParentId,
          zParentType: payload.zParentType,
        })
        .then(function (docRef) {
          payload.id = docRef.id
        })
    // console.log('Channel has just be created, id: ' + payload.id)
    commit('SET_CHANNEL', payload)

    // Solved.. add to "channels" by channels.push
    // seems working, test in comment:
    commit('ADD_TOCHANNELS', payload)
  },
  async updateChannel({ commit }, payload, notAddTags) {
    // console.log('updateChannel run: ' + payload.id)
    await db
      .collection('channels')
      .doc(payload.id)
      .update({
        channelName: payload.channelName,
        assignee: payload.assignee,
        owner: payload.owner,
        deleted: payload.deleted,
        createdAt: payload.createdAt,
        updatedAt: payload.updatedAt,
        zCusId: payload.zCusId,
        // zParentTask: payload.zParentTask,
        zParentId: payload.zParentId,
        zParentType: payload.zParentType,
      })
    commit('SET_CHANNEL', payload)
  },
  async deleteChannel({ state }, payload) {
    let payloadIndex = -1
    await db.collection("channels").doc(payload.id).delete().then(() => {
      // console.log('payload.id: ' + payload.id)
      payloadIndex = state.channels.indexOf(payload)
      // console.log('state.channels.indexOf(payload): ' + payloadIndex)
      if (payloadIndex > -1) {
        state.channels.splice( payloadIndex, 1 )
        // commit('SET_CHANNEL', state.channels[state.channels.length-1])
      }
    }).catch((error) => {
        console.error("Error removing channel: ", error);
    })
  },
  async setIsChannelDeleted({ commit }, payload) {
    commit('SET_ISCHANNELDELETED', payload)
  },
  setChannel({ commit }, payload) {
    commit('SET_CHANNEL', payload)
  },
  setSelectedChannelIdRParam({ commit }, payload) {
    commit('SET_SELECTEDCHANNELIDPARAM', payload)
  },
  setRouteFromRParam({ commit }, payload) {
    commit('SET_ROUTEFROMRPARAM', payload)
  },
  setChannelBeforeUpdate({ commit }, payload) {
    commit('SET_CHANNELBEFOREUPDATE', payload)
  },
  setChannels({ commit }, payload) {
    commit('SET_CHANNELS', payload)
  },
  clearChannels({ commit }) {
    commit('CLEAR_CHANNELS')
  },
  setSelectedTasks({ commit }, payload) {
    commit('SET_SELECTEDTASKS', payload)
  },
}

const mutations = { 
  SET_SELECTEDCHANNELIDPARAM(state, payload) {
    state.selectedChannelIdRParam = payload
  },
  SET_ROUTEFROMRPARAM(state, payload) {
    state.routeFromRParam = payload
  },
  SET_CHANNEL(state, payload) {
    // 1. Set the payload to state 'task'
    state.channel = payload
    // 2. Update 'task' into the existing "tasks" array
    state.channels.forEach(eachChannel => {
      if (eachChannel.id === payload.id) {
        eachChannel.channelName = payload.channelName
        eachChannel.assignee = payload.assignee
        eachChannel.owner = payload.owner
        eachChannel.deleted = payload.deleted
        eachChannel.createdAt = payload.createdAt
        eachChannel.updatedAt = payload.updatedAt
        eachChannel.zCusId = payload.zCusId
        // eachChannel.zParentTask = payload.zParentTask
        eachChannel.zParentId = eachChannel.zParentId
        eachChannel.zParentType = eachChannel.zParentType
      } 
    })
    state.channels.update
    // console.log('task.js; task is added with id: ' + state.task.id)
  },
  SET_CHANNELBEFOREUPDATE(state, taskBeforeUpdate) {
    state.taskBeforeUpdate = taskBeforeUpdate
  },
  SET_CHANNELS(state, payload) {
    state.channels = payload
  },
  CLEAR_CHANNELS(state) {
    state.channel = {}
    state.channels = []
    state.selectedChannelIdRParam = ''
    state.routeFromRParam = ''
  },
  ADD_TOCHANNELS(state, payload) {
    let isChannelFound = false
    state.channels.forEach(channel => {
      if (channel.id === payload.id) {
        isChannelFound = true
      }
    })
    if (!isChannelFound) {
      state.channels.push(payload)
    }
  },
  ADD_DELTACHANNELS(state, payload) {
    let isFound = false
    payload.forEach(deltaChannel => {
      state.channels.forEach(channel => {
        if (channel.id === deltaChannel.id) {
          isFound = true
        }
      })
      if (!isFound) {
        state.channels.push(deltaChannel)
      } else {
        // Still have to update the channel!
        let updatedChannelIndex = state.channels.findIndex(channel => channel.id == deltaChannel.id)
        state.channels[updatedChannelIndex] = deltaChannel
        state.channels.update
        isFound = false
      }
    })
    // state.channels.update
  },
  SET_ISCHANNELDELETED(start, payload) {
    state.isChannelDeleted = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
