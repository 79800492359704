// eslint-disable-next-line import/no-cycle
import { db } from '@/main'
export default {
  namespaced: true,

  state: {
    payments: [],
    // isPopupEnabled: true,
  },

  actions: {
    async getPayments({ commit }, payload) {
      let snapshot = await db.collection('payments')
      .where('cusId', '==', payload)
      .get()
      let apayments = [];
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        apayments.push(appData)
      })
      commit('SET_PRODUCTS', apayments)
    },
    // setIsPopupEnabled({ commit }, payload) {
    //   commit('SET_ISPOPUPENABLED', payload)
    // },
  },

  getters: {},

  mutations: {
    SET_PRODUCTS(state, payload) {
      state.payments = payload
    },
    // SET_ISPOPUPENABLED(state, payload) {
    //   state.isPopupEnabled = payload
    // },
  },

}
