import { mapState } from 'vuex'
import { format } from 'date-fns'
import rmvEmptyChannel from '@/mixins/rmvEmptyChannel'
export default {
  name: 'logoutProc',
  mixins: [ rmvEmptyChannel ],
  computed: {    
    ...mapState({
      userProfile: state => state.authen.userProfile,
    }),
    // geolocation:
    ...mapState({
      geoData: state => state.authen.geoData,
    }),
  },
  methods: {
    // delay(n){
    //   return new Promise(function(resolve){
    //     setTimeout(resolve, n*1000);
    //   });
    // },
    logoutProc() {
      this.rmEmptyChannels()
      //log logout history & logout
      let authItem = {}
      authItem.userName = this.userProfile.userName
      authItem.email = this.userProfile.email
      authItem.title = this.userProfile.title
      authItem.authType = 'logout'
      authItem.at = format(new Date(), "yyyy-MM-dd HH:mm:ss")
      if (this.isMobile()) {
        authItem.connection = 'mobile'
      } else {
        authItem.connection = 'web'
      }
      authItem.zCusId = this.userProfile.zCusId
      // geolocation
      authItem.geoInfo = this.geoData
      // console.log('running logoutprod')
      this.$store.dispatch('histAuth/addHistAuthItem', authItem)

      // turn off the force reload feature in App.vue
      this.$store.dispatch('authen/setForceReload', false)
      this.$store.dispatch('authen/logout')
      // window.location.reload()
      // history.go(0)
      window.location.href = window.location.href
    },  
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
  }
}