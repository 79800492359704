import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      comments: state => state.comment.comments,
    }),
    ...mapState({
      channels: state => state.channel.channels
    }),
  },
  methods: {
    rmEmptyChannels() {
      // console.log('rmEmptyChannels run')
      // console.log('Channel Length' + this.channels.length)
      // console.log('Comments Length' + this.comments.length)
      this.channels.forEach(channel => {
        if (this.comments.filter(comment => comment.zParentChannel === channel.id).length === 0) {
          // console.log('channel.id to be deleted: ' + channel.id)
          this.$store.dispatch('channel/deleteChannel', channel)
          this.$store.dispatch('channel/setIsChannelDeleted', true)
        }
      })
    },
  }
}
