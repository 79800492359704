import { db } from '@/main'

export default {
  namespaced: true,

  state: {
    faqs: [],
    faq: {},
  },

  getters: {
  },

  actions: {
    async getFaqs({ commit }, payload) {
        let snapshot = await db.collection("faqs")
          .limit(500)
          .get();
        let faqs = [];
        snapshot.forEach(doc => {
            let appData = doc.data()
            appData.id = doc.id
            faqs.push(appData)
        })
        commit('SET_FAQS', faqs)
    },
    async addFaq({ commit }, payload) {
        await db
            .collection('faqs')
            .add({
                name: payload.name,
                email: payload.email,
                category: payload.category,
                enquiry: payload.enquiry,
                answer: payload.answer,
                zCusId: payload.zCusId,
                createdAt: payload.createdAt,
                updatedAt: payload.updatedAt
            })
            .then(function (docRef) {
                payload.id = docRef.id
            })
        commit('ADD_FAQS', payload)
        commit('SET_FAQ', payload)
    },
  },

  mutations: { 
    SET_FAQS(state, payload) {
      state.faqs = payload
    },
    ADD_FAQS(state, payload) {
      state.faqs.push(payload)
    },
    SET_FAQ(state, payload) {
      state.faq = payload
    },
  },
}
