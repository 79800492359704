// eslint-disable-next-line import/no-cycle
import { db } from '@/main'
export default {
  namespaced: true,

  state: {
    mail: {},
  },

  actions: {    
    async sendEmail({ commit }, payload) {
      await db
        .collection('mail')
        .add({
          // cusId: payload.cusId,
          message: payload.message,
          to: payload.to,
          // planStorage: payload.planStorage,
          // purchasedStorage: payload.purchasedStorage,
          // sub_numTier: payload.sub_numTier,
          // sub_numStorage: payload.sub_numStorage,
          // sub_isResolved: payload.sub_isResolved,
          // createdAt: payload.createdAt,
          // updatedAt: payload.updatedAt,
          // zPartnerCode: payload.zPartnerCode,
          // promoCode: payload.promoCode
        })
        .then(function (docRef) {
          payload.id = docRef.id
        })
      commit('SET_SENDEMAIL', payload)
      // commit('SET_ISSITELOADED', true)
      // commit('SET_NEWSITECREATED')
    },
  },

  getters: {},

  mutations: {
    SET_SENDEMAIL(state, payload) {
      state.mail = payload
    },
  },

}
