import { db } from '@/main'

export default {
  namespaced: true,

  state: {
    historyItems: [],
    historyItem: {},
    notifyItems: [],  // consolidated array for notifications
  },

  getters: {
  },

  actions: {
    async getHistoryItems({ commit }, payload) {
      // const histItemsRef = db.collection('hist_app')
      // const isCusIdMatch = histItemsRef.where('zCusId', '==', payload.zCusId).limit(1000).get()
      // const isNotifiedMatch = histItemsRef.where('usersNotified', 'array-contains', payload.id).limit(1000).get()
      
      // const [cusIdQuerySnapshot, notifiedQuerySnapshot] = await Promise.all([
      //   isCusIdMatch,
      //   isNotifiedMatch
      // ]);

      // const cusIdArray = cusIdQuerySnapshot.docs;
      // const notifiedArray = notifiedQuerySnapshot.docs;

      // const histItemsSnapshot = cusIdArray.concat(notifiedArray);

      // let historyItems = []
      // histItemsSnapshot.forEach(doc => {
      //   let appData = doc.data()
      //   appData.id = doc.id
      //   historyItems.push(appData)
      // })
      // commit('SET_HISTORYITEMS', historyItems)
      let snapshot = await db.collection('hist_app')
        // .where('usersNotified', 'array-contains', payload.id)
        .where('zCusId', '==', payload.zCusId)
        .limit(100)
        .get();
      let historyItems = [];
      snapshot.forEach(historyItem => {
          let appData = historyItem.data()
          appData.id = historyItem.id
          historyItems.push(appData)
      })
      // state.historyTest = rootState.task.taskSize
      // rootState['task/taskSize']
      commit('SET_HISTORYITEMS', historyItems)
    },
    async getDeltaHistoryItems({ commit, rootState }, payload) {
      // console.log('payload.id: ' + payload.id)
      // console.log('payload.zCusId: ' + payload.zCusId)
      // console.log('rootState.authen.loginTime: ' + rootState.authen.loginTime)
        let snapshot = await db.collection('hist_app')
          .where('usersNotified', 'array-contains', payload.id)
          .where('at', '>=', rootState.authen.loginTime + '\uf8ff')
          .limit(50)
          .get();
          // .where('at', '>', rootState.authen.loginTime + '\uf8ff')
        let deltaHistoryItems = [];
        snapshot.forEach(doc => {
            let appData = doc.data()
            appData.id = doc.id
            deltaHistoryItems.push(appData)
        })
        // state.historyTest = rootState.task.taskSize
        // rootState['task/taskSize']
        commit('ADD_DELTAHISTORYITEMS', deltaHistoryItems)
    },
    async addHistoryItem({ commit }, payload) {
      // console.log('payload.module: ' + payload.module)
      // console.log('payload.moduleId: ' + payload.moduleId)
      // console.log('payload.moduleParentId: ' + payload.moduleParentId)
      // console.log('payload.moduleParentType: ' + payload.moduleParentType)
      // console.log('payload.at: ' + payload.at)
      // console.log('payload.by: ' + payload.by)
      // console.log('payload.usersNotified: ' + payload.usersNotified)
      // console.log('payload.zCusId: ' + payload.zCusId)
      // console.log('payload.action: ' + payload.action)
        await db
            .collection('hist_app')
            .add({
                action: payload.action,
                module: payload.module,
                moduleId: payload.moduleId,
                // for Comment, ease of coding, i.e. check channel before
                moduleParentId: payload.moduleParentId,
                moduleParentType: payload.moduleParentType,
                at: payload.at,
                by: payload.by,
                usersNotified: payload.usersNotified,
                zCusId: payload.zCusId,
            })
            .then(function (historyRef) {
                payload.id = historyRef.id
            })
        commit('ADD_TOHISTORYITEMS', payload)
        // commit('SET_HISTORYITEM', payload)
    },
    async updateHistoryItem({ commit }, payload) {
      await db
        .collection('hist_app')
        .doc(payload.id)
        .update({
          action: payload.action,
          module: payload.module,
          moduleId: payload.moduleId,
          // for Comment, ease of coding, i.e. check channel before
          moduleParentId: payload.moduleParentId,
          moduleParentType: payload.moduleParentType,
          at: payload.at,
          by: payload.by,
          usersNotified: payload.usersNotified,
          zCusId: payload.zCusId,
        })
      commit('SET_HISTORYITEM', payload)
    },    
    // Just remove from store
    // i.e. for updating notification items
    async deleteHistoryItem({ state }, payload) {
      let payloadIndex = -1
      await db.collection("hist_app").doc(payload.id).delete().then(() => {
          payloadIndex = state.historyItems.indexOf(payload)
          if (payloadIndex > -1) {
            state.historyItems.splice( payloadIndex, 1 )
            // commit('SET_TASK', state.historyItems[state.historyItems.length-1])
          }
      }).catch((error) => {
          console.error("Error removing task: ", error);
      })
    },
    // async deleteHistoryItem({ commit }, payload) {
    //     await db
    //         .collection('hist_app')
    //         .doc(payload)
    //         .delete();
    //     commit('SET_HISTORYITEM', null)
    // },
    setHistoryItems({ commit }, payload) {
        commit('SET_HISTORYITEMS', payload)
    },
    setNotifyItems({ commit }, payload) {
      commit('SET_NOTIFYITEMS', payload)
    },
  },

  mutations: {
    SET_HISTORYITEMS(state, payload) {
        state.historyItems = payload
        state.historyItems = state.historyItems
          .sort((a, b) => a.at > b.at ? -1 : 1)
    },
    SET_NOTIFYITEMS(state, payload) {
      state.notifyItems = payload
    },
    SET_HISTORYITEM(state, payload) {
      let isHistoryItemFound = false
      state.historyItem = payload
      // Update 'historyItem' into the existing "historyItems" array
      state.historyItems.forEach(historyItem => {
        if (historyItem.id === payload.id) {
          historyItem.action = payload.action
          historyItem.module = payload.module
          historyItem.moduleId = payload.moduleId
          historyItem.moduleParentId = payload.moduleParentId
          historyItem.moduleParentType = payload.moduleParentType
          historyItem.at = payload.at
          historyItem.by = payload.by
          historyItem.usersNotified = payload.usersNotified
          historyItem.zCusId = payload.zCusId
          isHistoryItemFound = true
        }
        if (!isHistoryItemFound) {
          state.historyItems.push(payload)
        }
      })
    },
    ADD_TOHISTORYITEMS(state, payload) {
      let isHistoryFound = false
      state.historyItems.forEach(historyItem=> {
        if (historyItem.id === payload.id) {
          isHistoryFound = true
        }
      })
      if (!isHistoryFound) {
        state.historyItems.push(payload)
        // console.log('moduleId ' + payload.moduleId + ' added to history' )
      }
        // state.historyItems.push(payload)
        // state.historyItems.update
        // state.responses = state.responses.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
    },
    ADD_DELTAHISTORYITEMS(state, payload) {
      let isHistoryFound = false
        payload.forEach(deltaHistoryItem => {
          state.historyItems.forEach(historyItem => {
            if (historyItem.id === deltaHistoryItem.id) {
              isHistoryFound = true
            }
          })
          if (!isHistoryFound) {
            state.historyItems.push(deltaHistoryItem)
          } else {
            isHistoryFound = false
          }
        })
        // state.historyItems.update
    },
  },
}
