import { db } from '@/main'
export default {
  namespaced: true,
  state: {
    chatgptItem: {},
  },
  actions: {
    async addChatGpt({ commit }, payload) {
      await db
          .collection('chatgpt')
          .add({
            prompt: payload.prompt,
            // zCusId: payload.zCusId,
            // status: {},
            // response: ''
          })
          .then(function (docRef) {
              payload.id = docRef.id
          })
      commit('SET_CHATGPTITEM', payload)
    },
    async getChatGptItem({ commit }, payload) {
      let snapshot = await db.collection('chatgpt')
        .doc(payload)
        .get()
      let chatgpt = {};
      chatgpt = snapshot.data()
      commit('SET_CHATGPTITEM', chatgpt)
    },
  },
  mutations: {
    SET_CHATGPTITEM(state, payload) {
      state.chatgptItem = payload
    },
  },
}