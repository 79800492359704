import { db } from '@/main'
import { format } from 'date-fns'

export default {
  namespaced: true,

  state: {
    // channel: [],  // channel is the selected tasks (or empty task) for comments
    updateList: false, // test only
    listUpdated: false, // test only
    comments: [],
    comment: {},
  },

  getters: {
    fetchCommentsByParentId: (state) => (id) => {
        return state.comments.filter(comment => comment.zParentChannel === id)
    },

    fetchCommentsById: (state) => (id) => {
        return state.comments.filter(comment => comment.id === id)
    },
  },

  actions: {
    async getComments({ commit }, payload) {
        // commit('SET_LOADINGSTATUS', 'loading')
        let snapshot = await db.collection("comments")
          .where('zCusId', '==', payload)
          .get();
        let comments = [];
        snapshot.forEach(doc => {
            let appData = doc.data()
            appData.id = doc.id
            comments.push(appData)
        })
        commit('SET_COMMENTS', comments)
        // commit('SET_LOADINGSTATUS', 'notLoading')
        // console.log('comments loaded')
    },
    async getDeltaComments({ commit, rootState }, payload) {
        // commit('SET_LOADINGSTATUS', 'loading')
        let snapshot = await db.collection("comments")
          .where('zCusId', '==', payload)
          .where('updatedAt', '>=', rootState.authen.loginTime + '\uf8ff')
          .limit(50)
          .get();
        let deltaComments = [];
        snapshot.forEach(doc => {
            let appData = doc.data()
            appData.id = doc.id
            deltaComments.push(appData)
        })
        // console.log('delta comment length: ' + deltaComments.length)
        // console.log('delta comment: ' + deltaComments[0].message)
        commit('ADD_DELTACOMMENTS', deltaComments)
        // commit('SET_LOADINGSTATUS', 'notLoading')
        // console.log('deltaComments loaded')
    },
    async addComment({ commit, dispatch }, payload) {
      await db
        .collection('comments')
        .add({
            // descriptions: payload.descriptions,
            // date: payload.date,
            // time: payload.time,
            message: payload.message,
            owner: payload.owner, //string, userId
            // likes: payload.likes, //array, userIds
            zReplyComment: payload.zReplyComment, //string, userId [for quoting the name]
            zCusId: payload.zCusId,
            zParentChannel: payload.zParentChannel,
            // zParentId: payload.zParentId,
            // zParentType: payload.zParentType, //'task' or 'goal'
            createdAt: payload.createdAt,
            updatedAt: payload.updatedAt
        })
        .then(function (docRef) {
            payload.id = docRef.id
        })
      commit('SET_COMMENT', payload)
      commit('ADD_TOCOMMENTS', payload)
              
      payload.instr = 'Add'
      dispatch('packageHistoryItem', payload)
    },
    async updateComment({ commit, dispatch }, payload) {
      await db
        .collection('comments')
        .doc(payload.id)
        .update({
            // descriptions: payload.descriptions,
            // date: payload.date,
            // time: payload.time,
            message: payload.message,
            owner: payload.owner, //string, userId
            // likes: payload.likes, //array, userIds
            zReplyComment: payload.zReplyComment, //string, userId [for quoting the name]
            zCusId: payload.zCusId,
            zParentChannel: payload.zParentChannel,
            // zParentId: payload.zParentId,
            // zParentType: payload.zParentType, //'task' or 'goal'
            // zCParentId: payload.zCParentId, //parent commend id
            // zLevel: payload.zLevel,
            createdAt: payload.createdAt,
            updatedAt: payload.updatedAt
        })
      commit('SET_COMMENT', payload)
      payload.instr = 'Update'
      dispatch('packageHistoryItem', payload)
    },
    async deleteComment({ state, dispatch },payload) {
      // delete history item first
      payload.instr = 'Delete'
      dispatch('packageHistoryItem', payload)

      let payloadIndex = -1
      // console.log('Comment successfully deleted: ' + payload.id);
      await db.collection("comments").doc(payload.id).delete().then(() => {
          payloadIndex = state.comments.indexOf(payload)
          if (payloadIndex > -1) {
            state.comments.splice( payloadIndex, 1 )
          }
      }).catch((error) => {
          console.error("Error removing comment: ", error);
      });
    },
    async packageHistoryItem({ dispatch, rootState }, payload) {
  
      // 1. prepare historyItem fields (other than 'action')
      let historyItem = {}
      let fuAction = ''
      
      historyItem.module = 'Comment'
      historyItem.moduleId = payload.id
      historyItem.moduleParentId = payload.zParentChannel
      historyItem.moduleParentType = 'Channel'
  
      historyItem.at = format(new Date(
          payload.updatedAt
        ), "yyyy-MM-dd HH:mm")
      historyItem.by = payload.owner
      historyItem.usersNotified = payload.usersNotified
      historyItem.zCusId = payload.zCusId
  
      // 2. prepare 'action' field acc. to payload.instr': 
      //  'Add' / 'Update' / 'Remove' history record        (task / comment / doc / note / blog )
      //  'Like' / 'Heart' / 'Happy' / 'Sad' history record (comment)
      // * only task and comment in notifications           (i.e. 'at' and 'by' fields)
      
      switch ( payload.instr ) {
        case 'Add':
          historyItem.action = 'Add'
          dispatch('history/addHistoryItem', historyItem, { root: true })
          break
        case 'Update':
          historyItem.action = 'Update'
          fuAction = 'Update'
          break
        case 'Remove':
          historyItem.action = 'Remove'
          fuAction = 'Remove'
          break
        case 'Delete':
          historyItem.action = 'Delete'
          fuAction = 'Delete'
      }

      // Proceed if 'Update' or 'Remove' or 'Delete'
      if ( fuAction !== '' ) {
        // Dig out the exiting historyItem from store, grab the id before being updated
        let mappedHistItem = rootState.history.historyItems.find(hItem => hItem.moduleId===payload.id)
        if ( mappedHistItem !== undefined ) {
          historyItem.id = mappedHistItem.id
          if ( fuAction == 'Remove' ) {
            historyItem.at = ''
            historyItem.usersNotified = []
          }
          if ( fuAction=='Update' || fuAction=='Remove' ) {
            dispatch('history/updateHistoryItem', historyItem, { root: true })
          }
          if ( fuAction == 'Delete' ) {
            dispatch('history/deleteHistoryItem', historyItem, { root: true })
          }
        }
      }
  
      // remove 'instr' field from payload
      delete payload.instr
      delete payload.usersNotified
      fuAction = ''
    },
    // clearComments({ commit }) {
    //     commit('CLEAR_COMMENTS')
    // },
    // setComment({ commit }, payload) {
    //     commit('SET_COMMENT', payload)
    // },
    // setChannel({ commit }, payload) {
    //   commit('SET_CHANNEL', payload)
    // },
    setUpdateList({ commit }, payload) {
      commit('SET_UPDATELIST', payload)
    },
    setlistUpdated({ commit }, payload) {
        commit('SET_LISTUPDATED', payload)
    },
  },

  mutations: {
    SET_UPDATELIST(state, updateList) {
        state.updateList = updateList
    },
    SET_LISTUPDATED(state, listUpdated) {
        state.listUpdated = listUpdated
    },
    // SET_CHANNEL(state, channel) {
    //     state.channel = channel
    // },
    SET_COMMENT(state, payload) {
      state.comment = payload
      // 2. Update 'comment' into the existing "comments" array
      state.comments.forEach(eachComment => {
        if (eachComment.id === payload.id) {
          eachComment.message = payload.message,
          eachComment.owner = payload.owner, //string, userId
          // eachComment.likes = payload.likes, //array, userIds
          eachComment.zReplyComment = payload.zReplyComment, //string, userId [for quoting the name]
          eachComment.zCusId = payload.zCusId,
          eachComment.zParentChannel = payload.zParentChannel,
          // eachComment.zParentId = payload.zParentId,
          // eachComment.zParentType = payload.zParentType, //'task' or 'goal'
          eachComment.createdAt = payload.createdAt,
          eachComment.updatedAt = payload.updatedAt
        }
      })
    },
    SET_COMMENTS(state, payload) {
      state.comments = payload
    },
    // CLEAR_COMMENTS(state) {
    //   state.comment = {}
    //   state.comments = []
    // },
    ADD_TOCOMMENTS(state, payload) {
        state.comments.push(payload)
        // state.comments.update
        // state.comments = state.comments.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
    },
    ADD_DELTACOMMENTS(state, payload) {
      let isFound = false
      payload.forEach(deltaComment => {
        state.comments.forEach(comment => {
          if (comment.id === deltaComment.id) {
            isFound = true
          }
        })
        if (!isFound) {
          state.comments.push(deltaComment)
          // state.comments.update
        } else {
          isFound = false
        }
      })
    },
  },
}
