// Field notes:
// 'sub_' means subscriptions purchased from users which needs manual work:
// - sub_isResolved: Has the subscription matters (i.e. both below) been manually resolved?
// (below fields: == 0 means resolved, >0 means manual resolution)
// - sub_numTier: Number of Tiers changed
// - sub_numStorage: Number of Storage changed 
import { db } from '@/main'
export default {
  namespaced: true,

  state: {
    site: {},
    referredSites: [],
    // sitesWithSameEmail: [],
    // checkingSitesWithSameEmail: false,
    isLoadingSite: false,
    isNewSiteCreated: false, // record if new site is created
    // 'mine', 'public', 'roles', 'done'
    // Control data loading : notes / docs / blogs / tasks
    loadingDataStage: '', 
  },

  actions: {
    async getSite({ commit, dispatch }, payload) {
      let snapshot = await db.collection('sites')
      .where('cusId', '==', payload)
      .get()
      let sites = [];
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        sites.push(appData)
      })
      commit('SET_SITE', sites[0])
      dispatch('setIsLoadingSite', true)
    },
    setIsLoadingSite({ commit }, payload) {
      commit('SET_ISLOADINGSITE', payload)
    }, 

    // For referal, payload should be cusId
    async getReferredSites({ commit }, payload) {
      let snapshot = await db.collection('sites')
      .where('promoCode', '==', payload)
      .get()
      let referredSites = [];
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        referredSites.push(appData)
      })
      commit('SET_REFERREDSITE', referredSites)
    },
    
    // // For referal, payload should be cusId
    // async getSitesWithSameEmail({ commit }, payload) {
    //   commit('SET_CHECKINGSITESWITHSAMEEMAIL', true)
    //   let snapshot = await db.collection('sites')
    //   .where('regUserEmail', '==', payload)
    //   .get()
    //   let sites = [];
    //   snapshot.forEach(doc => {
    //     let appData = doc.data()
    //     appData.id = doc.id
    //     sites.push(appData)
    //   })
    //   commit('SET_SITESWITHSAMEEMAIL', sites)
    // },
    
    // async setCheckingSitesWithSameEmail({ commit }, payload) {
    //   commit('SET_CHECKINGSITESWITHSAMEEMAIL', payload)
    // },

    async addSite({ commit }, payload) {
      await db
        .collection('sites')
        .add({
          cusId: payload.cusId,
          regUser: payload.regUser,
          regUserName: payload.regUserName,
          regUserEmail: payload.regUserEmail,
          since: payload.since,
          till: payload.till,
          till_purchasedStorage: payload.till_purchasedStorage,
          plan: payload.plan,
          numOfUsersLimit: payload.numOfUsersLimit,
          numOfUsersUsed: payload.numOfUsersUsed,
          storageUsed: payload.storageUsed,
          planStorage: payload.planStorage,
          purchasedStorage: payload.purchasedStorage,
          sub_numTier: payload.sub_numTier,
          sub_numStorage: payload.sub_numStorage,
          sub_isResolved: payload.sub_isResolved,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          zPartnerCode: payload.zPartnerCode,
          promoCode: payload.promoCode,
          promoCodeChecked: payload.promoCodeChecked
        })
        .then(function (docRef) {
          payload.id = docRef.id
        })
      commit('SET_SITE', payload)
    },
    
    async updateSite({ commit }, payload) {
      await db
        .collection('sites')
        .doc(payload.id)
        .update({
          cusId: payload.cusId,
          regUser: payload.regUser,
          regUserName: payload.regUserName,
          regUserEmail: payload.regUserEmail,
          since: payload.since,
          till: payload.till,
          till_purchasedStorage: payload.till_purchasedStorage,
          plan: payload.plan,
          numOfUsersLimit: payload.numOfUsersLimit,
          numOfUsersUsed: payload.numOfUsersUsed,
          storageUsed: payload.storageUsed,
          planStorage: payload.planStorage,
          purchasedStorage: payload.purchasedStorage,
          sub_numTier: payload.sub_numTier,
          sub_numStorage: payload.sub_numStorage,
          sub_isResolved: payload.sub_isResolved,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          zPartnerCode: payload.zPartnerCode,
          promoCode: payload.promoCode,
          promoCodeChecked: payload.promoCodeChecked
        })
      commit('SET_SITE', payload)
    },
    
    setLoadingDataStage({ commit }, payload) {
      commit('SET_LOADINGDATASSTAGE', payload)
    },
  },

  getters: {},

  mutations: {
    SET_SITE(state, payload) {
      state.site = payload
    },
    SET_REFERREDSITE(state, payload) {
      state.referredSites = payload.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1)
    },
    // SET_SITESWITHSAMEEMAIL(state, payload) {
    //   state.sitesWithSameEmail = payload
    //   state.checkingSitesWithSameEmail = false
    // },
    // SET_CHECKINGSITESWITHSAMEEMAIL(state, payload) {
    //   state.checkingSitesWithSameEmail = payload
    // },
    SET_ISLOADINGSITE(state, payload) {
      state.isLoadingSite = payload
    },
    SET_NEWSITECREATED(state, payload) {
      state.isNewSiteCreated = payload
    },
    SET_LOADINGDATASSTAGE(state, payload) {
      state.loadingDataStage = payload
    },
  },

}
